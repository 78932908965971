import React, { useEffect } from "react";
import "./assets/css/bootstrap.min.css";
import "./assets/css/app.min.css";
import "./assets/css/custom.css";
import "./assets/css/wysiwyg.css";
import "./assets/css/responsive.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import Login from "./containers/login/Login";
import { loadUser } from "./store/actions/auth";
import BeforeLoginRoutes from "./shared/Routes/BeforeLoginRoutes";
import PrivateRoutes from "./shared/Routes/PrivateRoutes";
import Dashboard from "./containers/dashboard/Dashboard";
import PageNotFound from "./containers/notfound/PageNotFound";
import Profile from "./containers/profile/Profile";
import AddLead from "./containers/leads/AddLead";
import AllLeads from "./containers/leads/AllLeads";
import ViewLead from "./containers/leads/ViewLead";
import EditLead from "./containers/leads/EditLead";
import AddEmployee from "./containers/employees/AddEmployee";
import AllEmployees from "./containers/employees/AllEmployees";
import ViewEmployee from "./containers/employees/ViewEmployee";
import EditEmployee from "./containers/employees/EditEmployee";
import AddCallback from "./containers/callbacks/AddCallback";
import AllCallbacks from "./containers/callbacks/AllCallbacks";
import ViewCallback from "./containers/callbacks/ViewCallback";
import EditCallback from "./containers/callbacks/EditCallback";
import AddStatus from "./containers/statuses/AddStatus";
import AllStatuss from "./containers/statuses/AllStatuss";
import ViewStatus from "./containers/statuses/ViewStatus";
import EditStatus from "./containers/statuses/EditStatus";
function App() {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <BeforeLoginRoutes exact path="/" component={Login} />
          <PrivateRoutes exact path="/dashboard" component={Dashboard} />
          <PrivateRoutes exact path="/profile" component={Profile} />
          <PrivateRoutes exact path="/employees" component={AllEmployees} />
          <PrivateRoutes exact path="/employees/add" component={AddEmployee} />
          <PrivateRoutes
            exact
            path="/employees/:id/view"
            component={ViewEmployee}
          />
          <PrivateRoutes
            exact
            path="/employees/:id/edit"
            component={EditEmployee}
          />
          <PrivateRoutes exact path="/leads" component={AllLeads} />
          <PrivateRoutes exact path="/leads/add" component={AddLead} />
          <PrivateRoutes exact path="/leads/:id/view" component={ViewLead} />
          <PrivateRoutes exact path="/leads/:id/edit" component={EditLead} />
          <PrivateRoutes exact path="/callbacks" component={AllCallbacks} />
          <PrivateRoutes exact path="/callbacks/add" component={AddCallback} />
          <PrivateRoutes
            exact
            path="/callbacks/:id/view"
            component={ViewCallback}
          />
          <PrivateRoutes
            exact
            path="/callbacks/:id/edit"
            component={EditCallback}
          />
          <PrivateRoutes exact path="/statuses" component={AllStatuss} />
          <PrivateRoutes exact path="/statuses/add" component={AddStatus} />
          <PrivateRoutes
            exact
            path="/statuses/:id/view"
            component={ViewStatus}
          />
          <PrivateRoutes
            exact
            path="/statuses/:id/edit"
            component={EditStatus}
          />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
