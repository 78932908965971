import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { getStatus } from "../../store/actions/statuss_action";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/statuss_enum";
import SingleView from "../../components/common/SingleView";
const ViewStatus = ({ getStatus, match, status: { loading, status } }) => {
  useEffect(() => {
    getStatus(match.params.id);
  }, [match]);
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        {!loading ? (
          status && (
            <SingleView
              data={status}
              inputFields={inputFields}
              label={PAGE_SINGLE_TITLE}
              link={LINK_URL}
              id={status.id}
            />
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ status: state.status });

const mapDispatchToProps = { getStatus };

export default connect(mapStateToProps, mapDispatchToProps)(ViewStatus);
