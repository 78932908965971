import React, { useEffect } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { getLead, editLead } from "../../store/actions/leads_action";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/leads_enum";
import {
  inputFields as CommentFields,
  initialValues,
  view_all_table,
  hide_buttons,
} from "../../shared/enums/notes_enum";
import SingleView from "../../components/common/SingleView";
import AddForm from "../../components/common/AddForm";
import DataTable from "../../components/common/DataTable";
import { useState } from "react";
import moment from "moment";
const ViewLead = ({ getLead, editLead, match, lead: { loading, lead } }) => {
  const [page, setPage] = useState(1);
  useEffect(() => {
    getLead(match.params.id);
  }, [match]);
  console.log("LEAD", lead);
  const submitFormClicked = async (values) => {
    if (lead) {
      if (lead.comments) {
        console.log();
        await editLead(lead.id, {
          comments: [
            ...lead.comments,
            {
              comment: values.comment,
              date_of_comment: moment().format("DD-MM-YYYY h:mm:ss a"),
            },
          ],
        });
        await getLead(match.params.id);
      } else {
        lead.comments = [
          {
            comment: values.comment,
            date_of_comment: moment().format("DD-MM-YYYY h:mm:ss a"),
          },
        ];
        await editLead(lead.id, { comments: lead.comments });
        await getLead(match.params.id);
      }
    }
  };
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        {!loading ? (
          lead && (
            <SingleView
              data={lead}
              inputFields={inputFields}
              label={PAGE_SINGLE_TITLE}
              link={LINK_URL}
              id={lead.id}
            />
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title"> Comments </h4>
                </div>
                {!loading ? (
                  lead &&
                  lead.comments && (
                    <DataTable
                      keys={view_all_table}
                      data={lead.comments}
                      field={LINK_URL}
                      page={page}
                      loading={loading}
                      hide_buttons={hide_buttons}
                    />
                  )
                ) : (
                  <div>
                    <Spinner />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title"> {PAGE_SINGLE_TITLE} </h4>
                  <p className="card-title-desc">
                    Enter Details to add {PAGE_SINGLE_TITLE}
                  </p>
                </div>
                <AddForm
                  edit={false}
                  submitForm={submitFormClicked}
                  inputFields={CommentFields}
                  initialValues={initialValues}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ lead: state.lead });

const mapDispatchToProps = { getLead, editLead };

export default connect(mapStateToProps, mapDispatchToProps)(ViewLead);
