export const PAGE_TITLE = "Users";
export const PAGE_SINGLE_TITLE = "User";
export const LINK_URL = "employees";
export const SEARCH_TERMS = ["title", "meta_title"];
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
  },
};
export const initialValues = {
  name: "",
};

export const view_all_table = [{ name: "Name", value: "name" }];
