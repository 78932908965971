export const inputFields = {
  comment: {
    type: "text",
    required: true,
    title: "Comment",
  },
};
export const initialValues = {
  comment: "",
};
export const view_all_table = [
  { name: "Comment", value: "comment" },
  { name: "Time", value: "date_of_comment" },
];

export const hide_buttons = { view: true, edit: true, delete: true };
