import moment from "moment";

export const PAGE_TITLE = "Leads";
export const PAGE_SINGLE_TITLE = "Lead";
export const LINK_URL = "leads";
export const SEARCH_TERMS = ["title", "meta_title"];
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
  },
  phone: {
    type: "string",
    required: true,
    title: "Phone",
  },
  budget: {
    type: "string",
    required: false,
    title: "budget",
  },
  requirements: {
    type: "text",
    required: false,
    title: "Requirements",
  },
  source: {
    type: "select",
    required: false,
    title: "Source",
    options: ["Instagram", "Facebook", "Direct", "Call", "Groups"],
  },
  status: {
    type: "related",
    required: false,
    title: "Status",
  },
  date_of_lead: {
    type: "string",
    required: false,
    title: "Date of Lead",
    inputType: "date",
  },
  company_name: {
    type: "string",
    required: false,
    title: "Company Name",
  },
};
export const initialValues = {
  name: "",
  phone: "",
  budget: "",
  requirements: "",
  source: "",
  status: "",
};
export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "phone", value: "phone" },
  { name: "budget", value: "budget" },
  { name: "source", value: "source" },
  { name: "date of lead", value: "date_of_lead", date: true },
  { name: "Status", value: "status" },
];
