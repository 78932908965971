import {
  GET_EMPLOYEES,
  ADD_EMPLOYEE,
  EDIT_EMPLOYEE,
  GET_EMPLOYEE,
  RESET_EMPLOYEE,
  EMPLOYEES_ERROR,
} from "../types/employee_type";

const initialState = {
  employees: null,
  employee: null,
  total_employees: 0,
  loading: true,
  error: {},
  employee_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_EMPLOYEES:
      return {
        ...state,
        employees: payload,
        loading: false,
        error: {},
      };

    case RESET_EMPLOYEE:
      return {
        ...state,
        employee: null,
        loading: true,
        error: {},
      };

    case ADD_EMPLOYEE:
      return {
        ...state,
        employee_message: payload,
        loading: false,
        error: {},
      };
    case GET_EMPLOYEE:
      return {
        ...state,
        employee: payload,
        loading: false,
        error: {},
      };
    case EDIT_EMPLOYEE:
      return {
        ...state,
        employee_message: payload,
        loading: false,
        error: {},
      };

    case EMPLOYEES_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
