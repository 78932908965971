import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCvOA1Qac2zmc-tlj91lr6e7YlcKtCT5Vg",
  authDomain: "lead-management-fe954.firebaseapp.com",
  projectId: "lead-management-fe954",
  storageBucket: "lead-management-fe954.appspot.com",
  messagingSenderId: "609871004968",
  appId: "1:609871004968:web:9b4457396913ed8247fc1d",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
