export const PAGE_TITLE = "Callbacks";
export const PAGE_SINGLE_TITLE = "Callback";
export const LINK_URL = "callbacks";
export const SEARCH_TERMS = ["title", "meta_title"];
export const inputFields = {
  lead: {
    type: "related",
    required: false,
    title: "Lead",
  },
  callback_date: {
    type: "string",
    required: true,
    title: "Callback Date",
    inputType: "date",
  },
  callback_time: {
    type: "string",
    required: true,
    title: "Callback Time",
    inputType: "time",
  },
  note: {
    type: "string",
    required: true,
    title: "note",
    inputType: "text",
  },

  status: {
    type: "select",
    required: false,
    title: "Status",
    options: [
      "Not Picking",
      "Talked",
      "Not Able To connect",
      "Pending",
      "Disconnected",
    ],
  },
};
export const initialValues = {
  note: "",
  callback_date: "",
  callback_time: "",
  status: "",
  lead: "",
};

export const view_all_table = [
  { name: "Lead", value: "lead" },
  { name: "Callback date", value: "callback_date" },
  { name: "Callback time", value: "callback_time" },
  { name: "Note", value: "note" },
  { name: "Status", value: "status" },
];
