import { combineReducers } from "redux";
import auth from "./auth_reducer";
import alert from "./alert_reducer";
import lead from "./lead_reducer";
import callback from "./callback_reducer";
import employee from "./employee_reducer";
import status from "./status_reducer";
export default combineReducers({
  auth,
  alert,
  lead,
  employee,
  callback,
  status,
});
